import React, { useContext, useEffect, useMemo, useState } from 'react';
import { SupportForm } from './components/SupportForm';
import { SupportTicketWrapper } from './styles';
import { BaseTemplate, PageHead } from '@myob/myob-widgets';
import { Successful } from './components/Successful';
import { Failed } from './components/Failed';
import { AccountSelectContext, AccountSelectContextValue } from '@my-account/account';
import { RequestBody, SupportTicketInfo } from './type';
import { isEmpty, omitBy } from 'lodash';
import LoadingWrapper from '../components/LoadingWrapper';
import { addSupportTicket, SupportTicketState } from './reducers/sendSupportTicket';
import { useDispatch, useSelector } from 'react-redux';
import { AppThunkDispatch, RootState } from 'stores';
import { navigateToUrl } from 'single-spa';
import { ErrorComponent } from 'components/ErrorComponent/ErrorComponent';
import { Forbidden } from 'components/Forbidden/Forbidden';
import { HttpStatus } from 'helpers/request';
import { ModalBox } from '../components/PopUpModal';
import { GtmManager } from '@my-account/tools';
import PremiumSupportNotification from './components/PremiumSupportNotification';

export const ContactSupport: React.FC = () => {
  const dispatch: AppThunkDispatch = useDispatch();
  const [showSupport, setShowSupport] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [premiumUser, setPremiumUser] = useState<boolean>(localStorage.getItem('isPremiumCustomer') === 'true');

  const {
    selected: selectedAccount,
    error: accountSelectError,
    isSoloAccount,
  } = useContext<AccountSelectContextValue>(AccountSelectContext);

  useEffect(() => {
    setPremiumUser(localStorage.getItem('isPremiumCustomer') === 'true');
  }, [localStorage.getItem('isPremiumCustomer')]);

  const {
    save: { isLoading, isError },
  } = useSelector<RootState, SupportTicketState>((state) => state.supportTicket);

  const handleOnSave = async (data: SupportTicketInfo) => {
    GtmManager.dataLayer({
      dataLayer: {
        event: 'contact_support_success',
        category_subcategory: `${data.category}_${data.subCategory}`,
        serial_number_exist: `${data.serialNumber}` ? 1 : 0,
      },
      dataLayerName: 'MyAccount',
    });

    GtmManager.dataLayer({
      dataLayer: {
        event: 'send_support_ticket',
      },
      dataLayerName: 'MyAccount',
    });

    await dispatch(addSupportTicket(omitBy({ ...data, clientId: selectedAccount.clientId }, isEmpty) as RequestBody));
    setShowSupport(false);
  };

  const goHomePage = () => {
    navigateToUrl('/account');
  };

  const handleOnDiscard = () => {
    setShowModal(false);
    goHomePage();
  };

  const handleGoBack = () => {
    setShowModal(false);
  };

  const content = useMemo(() => {
    if (isSoloAccount) {
      return <Forbidden pageTitle="" title="You don’t have access to the page" description="" />;
    }
    if (accountSelectError?.status === HttpStatus.Forbidden) {
      return (
        <Forbidden
          pageTitle="Contact support"
          title="You will need administrator access to see this page"
          description="Contact the primary contact of this account to get authorized access. If you need help, get in touch with our support team."
        />
      );
    } else if (selectedAccount) {
      if (showSupport) {
        return (
          <SupportTicketWrapper data-testid="supportTicketWrapper">
            <PageHead title="Contact support" className="page-head" />
            {premiumUser ? <PremiumSupportNotification /> : null}
            <SupportForm className="contact-support-form" onSave={handleOnSave} onCancel={() => setShowModal(true)} />
          </SupportTicketWrapper>
        );
      } else if (isError) {
        return <Failed onClick={() => setShowSupport(true)} />;
      } else {
        return <Successful onClick={() => goHomePage()} />;
      }
    }
    return <ErrorComponent pageTitle="Contact support" />;
  }, [selectedAccount, accountSelectError, showSupport, isError, premiumUser, isSoloAccount]);

  return (
    <div>
      {showModal && (
        <ModalBox
          onDiscard={handleOnDiscard}
          onGoBack={handleGoBack}
          title="Cancel support request?"
          message="Are you sure you want to discard your support request?"
        />
      )}
      <LoadingWrapper isLoading={isLoading}>
        <BaseTemplate>{content}</BaseTemplate>
      </LoadingWrapper>
    </div>
  );
};
