import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getIsUnpaidBill } from 'helpers/api';
import { ResponseError } from 'helpers/request';

export type UnpaidBillState = {
  fetch: {
    isLoading: boolean;
    isError: boolean;
    data: boolean;
    error: ResponseError;
  };
};

export const initialState: UnpaidBillState = {
  fetch: {
    isLoading: false,
    isError: false,
    data: null,
    error: {
      status: null,
      message: null,
    },
  },
};

export const getIsUnpaidBillAsync = createAsyncThunk<boolean, string, { rejectValue: ResponseError }>(
  'getIsUnpaidBill',
  async (clientId, thunkApi) => {
    try {
      return await getIsUnpaidBill(clientId);
    } catch (e) {
      return thunkApi.rejectWithValue(e);
    }
  },
);

const slice = createSlice({
  name: 'unpaid-bill',
  reducers: {},
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getIsUnpaidBillAsync.pending, (state) => {
      state.fetch.isLoading = true;
      state.fetch.isError = false;
      state.fetch.data = null;
      state.fetch.error = { status: null, message: null };
    });
    builder.addCase(getIsUnpaidBillAsync.fulfilled, (state, action) => {
      state.fetch.isLoading = false;
      state.fetch.isError = false;
      state.fetch.data = action.payload;
      state.fetch.error = { status: null, message: null };
    });
    builder.addCase(getIsUnpaidBillAsync.rejected, (state, action) => {
      state.fetch.isLoading = false;
      state.fetch.isError = true;
      state.fetch.data = null;
      state.fetch.error = action.payload;
    });
  },
});
export const unpaidBillReducer = slice.reducer;
