import React, { useEffect, useRef, useState } from 'react'
import { DownChevronIcon, UpChevronIcon, RadioButton, ToggleContent } from '@myob/myob-widgets'
import { IntermediatePaymentCard } from 'modules/PaymentProfile/type'
import Config from 'config'
import masterCardIcon from 'assets/images/masterCard.svg'
import visaIcon from 'assets/images/visa.svg'
import americaExpressIcon from 'assets/images/americanExpress.svg'
import { EditBodyWrapper, EditHeaderWrapper, EditIframe, EditWrapper, IconWrapper } from 'modules/PaymentProfile/styles'
import { BackendPostCreditCard } from 'modules/PaymentProfile/reducers/type'
import {
    digestPaymentProfileData,
    isValidWestpacAffiliatedBankCreditPromotion,
    isValidWestpacCreditPromotion,
} from '../../../../helpers/paymentProfile'
import { useSelector } from 'react-redux'
import { RootState } from 'stores'

interface PCIPortholeData {
    data: {
        outcome: {
            cardDetails?: BackendPostCreditCard
            height: number,
            action?: string
        }
    }
}

type FormProps = {
    isActive: boolean
    onChange: (update: (prev: IntermediatePaymentCard) => IntermediatePaymentCard) => void
    onHeaderClick: () => void
}

const CreditCardForm: React.FC<FormProps> = ({ onChange, isActive, onHeaderClick }) => {
    const [showWestpacError, setShowWestpacError] = useState(false)
    const [showWestpacErrorMsg, setShowWestpacErrorMsg] = useState('')
    const [iframeHeight, setIframeHeight] = useState(264)
    const refIframeContainer = useRef(null)
    const paymentProfile = useSelector((state: RootState) => state.paymentProfileAccountView)
    const isWestPacPromotion = digestPaymentProfileData(paymentProfile).isWestPacPromotion
    const isWestpacAffiliatedBankPromotion = digestPaymentProfileData(paymentProfile).isWestpacAffiliatedBankPromotion

    useEffect(() => {
        onChange((prev): IntermediatePaymentCard => ({ ...prev, detail: undefined }))
    }, [isActive, onChange])

    const showWestpacPromotionErrMsg = (prefix: string) => {
        if (isWestPacPromotion && !isValidWestpacCreditPromotion(prefix)) {
            setShowWestpacError(true)
            setShowWestpacErrorMsg('Please add a valid Westpac business debit or credit card or business transaction account')
        }else if (isWestpacAffiliatedBankPromotion && !isValidWestpacAffiliatedBankCreditPromotion(prefix)) {
            setShowWestpacError(true)
            setShowWestpacErrorMsg('Please add a valid St.George Bank, Bank of Melbourne or Bank SA business debit or credit card or business transaction account')
        }
        else {
            setShowWestpacError(false)
        }
    }

    const establishIframeCommunication = () => {
        const listener = (ev: WindowEventMap['message']) => {
            if (ev.origin !== Config.PCI_CARD_PORTAL_ORIGIN) {
                return
            }
            const pciPortholeData = JSON.parse(ev.data) as PCIPortholeData
            setIframeHeight(pciPortholeData.data.outcome.height)
            onChange((prev): IntermediatePaymentCard => {
                const first_six_digits = pciPortholeData.data.outcome.cardDetails?.first_six_digits
                if (first_six_digits) showWestpacPromotionErrMsg(first_six_digits)
                return { ...prev, detail: pciPortholeData.data.outcome.cardDetails }
            })
        }
        window.addEventListener('message', listener, false)
        return () => window.removeEventListener('message', listener)
    }
    useEffect(establishIframeCommunication, [])

    useEffect(() => {
        if (window.matchMedia('(max-width: 400px)')?.matches) {
            setIframeHeight(360);
        }
    }, [])

    return (
        <EditWrapper style={{ marginBottom: '1.2rem' }}>
            <ToggleContent
                visible
                renderContent={(): JSX.Element => (
                    <EditHeaderWrapper onClick={onHeaderClick}>
                        <RadioButton
                            id="creditRadio"
                            label="Credit / debit card"
                            name="creditDebitCard"
                            checked={isActive}
                            onChange={() => ({})}
                        />
                        <img src={masterCardIcon} alt="masterCard" />
                        <img src={visaIcon} alt="visa" />
                        <img src={americaExpressIcon} alt="americanExpress" />
                        <IconWrapper>{isActive ? <DownChevronIcon /> : <UpChevronIcon />}</IconWrapper>
                    </EditHeaderWrapper>
                )}
            >
                {isActive && (
                    <>
                        <EditBodyWrapper style={{ marginBottom: 0 }}>
                            {showWestpacError ? (
                                <span className="card-number-errMsg">
                                    {showWestpacErrorMsg}
                                </span>
                            ) : (
                                <></>
                            )}
                            <EditIframe
                                data-cy="edit-credit-card-iframe"
                                id="edit-credit-card-iframe"
                                ref={refIframeContainer}
                                src={Config.PCI_CARD_PORTAL_URL}
                                style={{ height: iframeHeight + 'px' }}
                            />
                        </EditBodyWrapper>
                    </>
                )}
            </ToggleContent>
        </EditWrapper>
    )
}

export default CreditCardForm
