import { navigateToUrl } from 'single-spa';
import { auth } from '@my-account/tools';
import { SignOutIcon } from '@myob/myob-widgets';

const logoutHash = '#logout';

const handleKeyPress = (event, handler) => {
  if (event.key === 'Enter') {
    handler(event);
  }
};

const handleClick = (url, event) => {
  navigateToUrl(url);
  event.preventDefault();
};

const handleLogoutClick = (isAccountView, event) => {
  if (isAccountView) {
    auth.logout('/account');
  } else {
    auth.logout();
  }
  event.preventDefault();
};

const buildSettingMenuItems = (isAccountView) => [
  {
    key: 'contactDetails',
    label: 'Contact details',
    url: '/account/contact-details',
    onKeyDown: (event) => handleKeyPress(event, handleClick.bind(null, '/account/contact-details')),
    onClick: (event) => handleClick('/account/contact-details', event),
  },
  {
    key: 'security',
    label: 'Account security',
    url: '/account/security',
    onKeyDown: (event) => handleKeyPress(event, handleClick.bind(null, '/account/security')),
    onClick: (event) => handleClick('/account/security', event),
  },
  {
    key: 'communicationPreferences',
    label: 'Communication preference',
    url: '/account/communication-preferences',
    onKeyDown: (event) => handleKeyPress(event, handleClick.bind(null, '/account/communication-preferences')),
    onClick: (event) => handleClick('/account/communication-preferences', event),
  },
  {
    key: 'logout',
    label: 'Log out',
    icon: SignOutIcon,
    url: logoutHash,
    onKeyDown: (event) => handleKeyPress(event, handleLogoutClick.bind(null, isAccountView)),
    onClick: (event) => handleLogoutClick(isAccountView, event),
  },
];

export const settingTree = (isAccountView) => {
  return buildSettingMenuItems(isAccountView);
};
