import { telemetry } from '@my-account/tools';

function getBusinessIdByUrl(): string {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get('businessId');
}

export function getBusinessId(): string {
  const cachedBusinessId = localStorage.getItem('businessId');
  const businessIdByUrl = getBusinessIdByUrl();

  return businessIdByUrl || cachedBusinessId || '';
}

export function initBusinessId(): void {
  const businessIdByUrl = getBusinessIdByUrl();
  const cachedBusinessId = localStorage.getItem('businessId');

  if (businessIdByUrl && cachedBusinessId !== businessIdByUrl) {
    localStorage.setItem('businessId', businessIdByUrl);
  }
  telemetry.initBusinessId().setBusinessId(businessIdByUrl || cachedBusinessId);
}

export function isAccountSelectPage() {
  return /^\/account\/?$/i.test(window.location.pathname);
}

export function checkSoloAccount(isSoloAccount) {
  return isSoloAccount;
}
