import React, { useEffect, useState, useContext } from 'react'
import { AccountSelectContext, AccountSelectContextValue } from '@my-account/account'
import { Alert } from '@myob/myob-widgets'
import { navigateToUrl } from 'single-spa'
import { ERoutePath } from 'router'
import { useDispatch, useSelector } from 'react-redux'
import { ga } from '@my-account/tools'
import EditModal from 'modules/PaymentProfile/components/EditModal'
import { updatePaymentProfileByAccountId } from 'modules/PaymentProfile/reducers'
import { ValidatedPaymentCard } from 'modules/PaymentProfile/type'
import { RootState } from 'stores'
import { ENotificationType, NotificationState } from 'stores/reducers/notification/type'
import { ETelemetryActionsAndLabels, ETelemetryNames } from 'telemetry/type'
import * as telemetry from 'telemetry'
import { ArrearsState } from 'stores/reducers/arrears/type'
import {
    trackClickInArrearsBanner,
    trackGtmClickInArrearsBanner,
    trackGtmViewedArrearsBanner,
    trackViewedArrearsBanner,
} from './helper'
import { sendGtmModalEventWithoutPage } from '../../helpers/ga'

type ArrearsBannerProps = {
    parentPage: ETelemetryNames
}

export const ArrearsBanner: React.FC<ArrearsBannerProps> = ({ parentPage }): JSX.Element => {
    const [showModal, setShowModal] = useState(false)
    const [showSpinner, setShowSpinner] = useState(false)
    const [bannerAlreadySeen, setBannerAlreadySeen] = useState(false)
    const { selected } = useContext<AccountSelectContextValue>(AccountSelectContext)
    const clientId = selected?.clientId
    const dispatch = useDispatch()
    const arrears = useSelector<RootState, ArrearsState>((state) => state.arrears)
    const notification = useSelector<RootState, NotificationState>((state) => state.notification)
    const numOfInvoices = arrears.pastDueInvoices?.invoices?.length || 0
    const hasArchieInvoices =
        (arrears.pastDueInvoices?.invoices.map((i) => i.invoiceNumber).filter((i) => i.includes('1-')).length || 0) > 0
    const updateModalStatus = (status: boolean) => {
        setShowModal(status)
        ga.push({
            event: status ? 'modalOpened' : 'modalClosed',
            modalName: 'EditPaymentProfile',
        })
        sendGtmModalEventWithoutPage(status ? 'open' : 'close', 'edit pp')
    }
    useEffect(() => {
        if (arrears.isArrears && !bannerAlreadySeen) {
            setBannerAlreadySeen(true)
            trackViewedArrearsBanner(parentPage, arrears.needUpdateDetail, hasArchieInvoices)
            trackGtmViewedArrearsBanner(parentPage, arrears.needUpdateDetail, hasArchieInvoices)
        }
    }, [bannerAlreadySeen, arrears.isArrears, arrears.needUpdateDetail, parentPage, hasArchieInvoices])
    useEffect(() => {
        const { type, secondaryMessage } = notification
        if (type === ENotificationType.Clear) {
            return
        }
        updateModalStatus(false)
        setShowSpinner(false)
        const optionalErrorInformation =
            type === ENotificationType.Success
                ? {}
                : {
                      actualError: secondaryMessage,
                  }
        telemetry.track(ETelemetryNames.PaymentProfile, {
            action: ETelemetryActionsAndLabels.Create,
            label: ETelemetryActionsAndLabels.Create,
            createData: {
                status: type === ENotificationType.Success ? 'success' : 'fail',
                ...optionalErrorInformation,
            },
        })

        type === ENotificationType.Success
            ? ga.push({
                  event: 'genericFormSuccess',
              })
            : ga.push({
                  event: 'genericFormFail',
                  errorText: secondaryMessage,
              })
    }, [notification])
    const notificationMessages = {
        successMessage:
            '<strong>Your new payment details have been saved and will be updated shortly.</strong><br />All outstanding invoices will be debited from your account in the next 24 hrs.',
        failureMessage:
            "Sorry, we couldn't update your payment details. Please try again in a few minutes or contact our support team.",
    }
    const toMyBillsPage = () => {
        trackClickInArrearsBanner(ETelemetryNames.PaymentProfile, 'ArrearsOutstandingInvoicesButton')
        trackGtmClickInArrearsBanner(ETelemetryNames.PaymentProfile, 'Arrears Outstanding Invoices')
        navigateToUrl(ERoutePath.AccountViewMyBills)
    }
    const toPaymentDetailsPage = () => {
        trackClickInArrearsBanner(ETelemetryNames.MyBills, 'ArrearsManagePaymentProfileButton')
        trackGtmClickInArrearsBanner(ETelemetryNames.MyBills, 'Arrears Manage Payment Profile')
        navigateToUrl(ERoutePath.AccountViewPaymentDetails)
    }

    const onCardEdit = ({ detail: body }: ValidatedPaymentCard) => {
        if (clientId) {
            dispatch(updatePaymentProfileByAccountId({ clientId, body, notificationMessages }))
            setShowSpinner(true)
        }
    }

    const primaryMessageForPaymentDetailsPage = (
        <strong>Please {arrears.needUpdateDetail ? 'update' : 'check'} your payment details</strong>
    )
    const primarySuccessReAttemptMessage = <strong>Thanks for confirming your payment details</strong>
    const secondarySuccessReAttemptMessage = <p>We&apos;ll process outstanding payments in the next 3 days.</p>
    const updatePaymentDetails = (
        <span style={{ cursor: 'pointer' }} onClick={(): void => updateModalStatus(true)}>
            <b>
                <u>update your payment details</u>
            </b>
        </span>
    )
    const detailMessage = arrears.needUpdateDetail ? (
        <span>Please {updatePaymentDetails} and</span>
    ) : (
        <span data-testid="confirm-payment-detail-msg">
            Check you have enough money in your account and confirm your payment details here. After you&apos;ve
            confirmed your details,
        </span>
    )
    const primaryMessageYourAccountIsInArrears = <strong>Your account is in arrears</strong>
    const secondaryMessage = (
        <p>
            We were unable to process your payment for your MYOB subscription. {detailMessage} we&apos;ll process
            payment of <b>${arrears.pastDueInvoices?.outstandingAmount.toFixed(2)}</b> for{' '}
            <span onClick={toMyBillsPage} style={{ cursor: 'pointer', textDecoration: 'underline' }}>
                <b>{numOfInvoices}</b> outstanding invoice
                {numOfInvoices > 1 ? 's' : ''}.
            </span>
        </p>
    )

    const alertToDisplay = (reattemptProcessed: boolean) => {
        return reattemptProcessed ? (
            <Alert type="success" inline secondaryMessage={secondarySuccessReAttemptMessage} secondaryMessageVisible>
                {primarySuccessReAttemptMessage}
            </Alert>
        ) : (
            <Alert type="danger" inline secondaryMessage={secondaryMessage} secondaryMessageVisible>
                {primaryMessageForPaymentDetailsPage}
            </Alert>
        )
    }

    const secondaryMessageForBillsPage = (
        <p data-testid="confirm-arrears-msg-bills-page">
            Please pay your <b>${arrears.pastDueInvoices?.outstandingAmount.toFixed(2)}</b> for{' '}
            <span>
                <b>{numOfInvoices}</b> overdue invoice
                {numOfInvoices > 1 ? 's' : ''}
            </span>{' '}
            now or{' '}
            <span onClick={toPaymentDetailsPage} style={{ cursor: 'pointer', textDecoration: 'underline' }}>
                click here
            </span>{' '}
            to update your payment details and pay outstanding invoices.
        </p>
    )
    const secondaryMessageForBillsPageWhenArchieInvoices = (
        <p data-testid="confirm-arrears-banner-with-archie-invoices-my-bills-page">
            Please pay your{' '}
            <span>
                <b>{numOfInvoices}</b> overdue invoice
                {numOfInvoices > 1 ? 's' : ''}
            </span>{' '}
            below. To update your payment details,{' '}
            <span onClick={toPaymentDetailsPage} style={{ cursor: 'pointer', textDecoration: 'underline' }}>
                click here
            </span>{' '}
        </p>
    )
    const billsPageAlertToDisplay = () => {
        return hasArchieInvoices ? (
            <Alert
                type="danger"
                inline
                secondaryMessage={secondaryMessageForBillsPageWhenArchieInvoices}
                secondaryMessageVisible
            >
                {primaryMessageYourAccountIsInArrears}
            </Alert>
        ) : (
            <Alert type="danger" inline secondaryMessage={secondaryMessageForBillsPage} secondaryMessageVisible>
                {primaryMessageYourAccountIsInArrears}
            </Alert>
        )
    }
    return arrears.isArrears ? (
        <div data-testid="arrears-banner">
            {showModal && (
                <EditModal
                    isProcessing={showSpinner}
                    cancelModal={(): void => updateModalStatus(false)}
                    onSaveValidData={onCardEdit}
                    setProcessing={setShowSpinner}
                />
            )}
            {parentPage === ETelemetryNames.PaymentProfile
                ? alertToDisplay(arrears.reAttemptProcessed)
                : billsPageAlertToDisplay()}
        </div>
    ) : (
        <div></div>
    )
}

export default ArrearsBanner
