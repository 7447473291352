import React, { useEffect, useState, useContext } from 'react'
import { ga } from '@my-account/tools'
import { AccountNumber, CardWrapper, CreditCardProfile, ButtonWrapper } from 'modules/PaymentProfile/styles'
import EditModal from 'modules/PaymentProfile/components/EditModal'
import { Button, BankLgIcon, Input, MonthPicker } from '@myob/myob-widgets'

import masterCardIcon from 'assets/images/masterCard.svg'
import americaExpressIcon from 'assets/images/americanExpress.svg'
import visaIcon from 'assets/images/visa.svg'
import { ECreditCardType, IPaymentProfileItem } from 'modules/PaymentProfile/reducers/type'
import { ValidatedPaymentCard } from 'modules/PaymentProfile/type'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'stores'
import { ENotificationType, NotificationState } from 'stores/reducers/notification/type'
import * as telemetry from 'telemetry'
import { isCreditCardPaymentProfile, isDirectDebitPaymentProfile } from 'helpers/paymentProfile'
import { ETelemetryActionsAndLabels, ETelemetryNames } from 'telemetry/type'
import { generateExpiryDate } from 'helpers/tools'
import { updatePaymentProfileByAccountId } from 'modules/PaymentProfile/reducers'
import { AccountSelectContext, AccountSelectContextValue } from '@my-account/account'
import { ArrearsState } from 'stores/reducers/arrears/type'
import { reAttemptPaymentsForPastDueInvoices } from 'stores/reducers/arrears'
import { sendGtmFormSubmitEvent, sendGtmModalEventWithoutPage } from '../../../../helpers/ga'

type BankCardProps = {
    paymentItem: IPaymentProfileItem
    supportEdit?: boolean
}

const iconMap = {
    [ECreditCardType.MasterCard]: masterCardIcon,
    [ECreditCardType.Visa]: visaIcon,
    [ECreditCardType.AmericanExpress]: americaExpressIcon,
}

const BankCard: React.FC<BankCardProps> = ({ paymentItem, supportEdit = true }) => {
    const [showModal, setShowModal] = useState(false)
    const [showSpinner, setShowSpinner] = useState(false)

    const { selected } = useContext<AccountSelectContextValue>(AccountSelectContext)
    const clientId = selected?.clientId
    const region = selected?.region

    const dispatch = useDispatch()
    const arrears = useSelector<RootState, ArrearsState>((state) => state.arrears)
    const notification = useSelector<RootState, NotificationState>((state) => state.notification)

    const updateModalStatus = (status: boolean) => {
        setShowModal(status)
        ga.push({
            event: status ? 'modalOpened' : 'modalClosed',
            modalName: 'EditPaymentProfile',
        })
        const action = status ? 'open' : 'close'
        sendGtmModalEventWithoutPage(action, 'edit pp')
    }

    const invoices = arrears.pastDueInvoices?.invoices
    const processReAttempt = () => {
        dispatch(reAttemptPaymentsForPastDueInvoices({ invoices: invoices, client_id: clientId, region: region! }))
    }

    useEffect(() => {
        const { type, secondaryMessage } = notification
        if (type === ENotificationType.Clear) {
            return
        }
        updateModalStatus(false)
        setShowSpinner(false)
        const optionalErrorInformation =
            type === ENotificationType.Success
                ? {}
                : {
                      actualError: secondaryMessage,
                  }
        const submitResult = type === ENotificationType.Success ? 'success' : 'fail'
        telemetry.track(ETelemetryNames.PaymentProfile, {
            action: ETelemetryActionsAndLabels.Create,
            label: ETelemetryActionsAndLabels.Create,
            createData: {
                status: submitResult,
                ...optionalErrorInformation,
            },
        })
        sendGtmFormSubmitEvent('pp', 'submit edit pp', submitResult)
        type === ENotificationType.Success
            ? ga.push({
                  event: 'genericFormSuccess',
              })
            : ga.push({
                  event: 'genericFormFail',
                  errorText: secondaryMessage,
              })
    }, [notification])

    const notificationMessages = {
        successMessage:
            '<strong>Your new payment details have been saved and will be updated shortly.</strong><br />All outstanding invoices will be debited from your account in the next 24 hrs.',
        failureMessage:
            "Sorry, we couldn't update your payment details. Please try again in a few minutes or contact our support team.",
    }

    const onCardEdit = ({ detail: body }: ValidatedPaymentCard) => {
        if (clientId) {
            dispatch(updatePaymentProfileByAccountId({ clientId, body, notificationMessages }))
            setShowSpinner(true)
        }
    }

    const UpdataPaymentDetailsButton = () => {
        return (
            <>
                {supportEdit ? (
                    arrears.isArrears && !arrears.needUpdateDetail ? (
                        <ButtonWrapper>
                            <span>Are these payment details correct?</span>

                            <Button
                                id="no_update_details_button"
                                className="update_payment_button"
                                type="secondary"
                                onClick={(): void => updateModalStatus(true)}
                                style={{
                                    marginRight: '2%',
                                }}
                            >
                                No, update details
                            </Button>

                            <Button id="re_attempt_past_invoices_button" onClick={(): void => processReAttempt()}>
                                Yes, confirm and reattempt
                            </Button>
                        </ButtonWrapper>
                    ) : (
                        <Button id="update_payment_button" onClick={(): void => updateModalStatus(true)}>
                            Update payment details
                        </Button>
                    )
                ) : (
                    <></>
                )}
            </>
        )
    }

    return (
        <CardWrapper className="cardWrapper" data-testid="bankCard">
            {showModal && (
                <EditModal
                    isProcessing={showSpinner}
                    setProcessing={setShowSpinner}
                    cancelModal={(): void => updateModalStatus(false)}
                    onSaveValidData={onCardEdit}
                />
            )}

            {isCreditCardPaymentProfile(paymentItem) ? (
                <CreditCardProfile>
                    <div>
                        <img src={iconMap[paymentItem.credit_card.type]} alt="credit card icon" />
                    </div>
                    <Input
                        name="Cardholder_name"
                        type="text"
                        label="Cardholder name"
                        value={paymentItem.credit_card.name_on_card}
                        readOnly
                    />
                    <Input
                        name="Card_number"
                        label="Card number"
                        value={`****-****-****-${paymentItem.credit_card.last_four_digits}`}
                        readOnly
                    />
                    <MonthPicker
                        name="report-start-month"
                        label="Expiry date"
                        value={generateExpiryDate(
                            paymentItem.credit_card.expiry_year,
                            paymentItem.credit_card.expiry_month
                        )}
                        disabled
                    />
                    <UpdataPaymentDetailsButton />
                </CreditCardProfile>
            ) : (
                <AccountNumber>
                    <BankLgIcon set="lg" />
                    <div>
                        {isDirectDebitPaymentProfile(paymentItem) && (
                            <Input name="BSB" label="BSB" value={paymentItem.direct_debit.bsb} readOnly />
                        )}
                        {isDirectDebitPaymentProfile(paymentItem) && (
                            <Input
                                name="Account_number"
                                label="Account number"
                                value={paymentItem.direct_debit.account_number}
                                readOnly
                            />
                        )}
                        <UpdataPaymentDetailsButton />
                    </div>
                </AccountNumber>
            )}
        </CardWrapper>
    )
}
export default BankCard
