import React, { useContext, useEffect, useState } from 'react'
import {
    AddPaymentDetailWrapper,
    ModalBodyWrapper,
    NotificationBody,
    NotificationButtonWrapper,
} from 'modules/PaymentProfile/styles'
import addPaymentDetailIcon from 'assets/images/addPaymentDetail.svg'
import EditModal from '../EditModal'
import { ValidatedPaymentCard } from '../../type'
import { updatePaymentProfileByAccountId } from '../../reducers'
import { useDispatch, useSelector } from 'react-redux'
import { AccountSelectContext, AccountSelectContextValue } from '@my-account/account'
import { ga } from '@my-account/tools'
import { Button, Modal } from '@myob/myob-widgets'
import { Region } from '../../../../stores/type'
import ContactUs from '../../../../components/ContactUs'
import { RootState } from '../../../../stores'
import { ENotificationType, NotificationState } from '../../../../stores/reducers/notification/type'
import { sendGtmButtonEvent, sendGtmLinkEvent, sendGtmModalEventWithoutPage } from '../../../../helpers/ga'
import {featureFlags} from "../../../../configs/FeatureFlags";

export type AddPaymentDetailProps = {
    pageName: string
    region?: Region
}

const AddPaymentDetail: React.FC<AddPaymentDetailProps> = ({ pageName, region }) => {
    const dispatch = useDispatch()
    const { selected } = useContext<AccountSelectContextValue>(AccountSelectContext)
    const [showModal, setShowModal] = useState(false)
    const [showNotification, setShowNotification] = useState(true)
    const [showSpinner, setShowSpinner] = useState(false)
    const clientId = selected?.clientId
    const notification = useSelector<RootState, NotificationState>((state) => state.notification)

    const notificationMessages = {
        successMessage:
            '<strong>Your new payment details have been saved and will be updated shortly.</strong><br />All outstanding invoices will be debited from your account in the next 24 hrs.',
        failureMessage:
            "Sorry, we couldn't update your payment details. Please try again in a few minutes or contact our support team.",
    }

    const onModalOpen = () => {
        setShowModal(true)
        ga.push({
            event: 'analyticElementClick',
            'gtm.elementId': `btn_add_a_new_payment_detail`,
        })
        ga.push({
            event: 'modalOpened',
            modalName: 'EditPaymentProfile',
        })
        sendGtmButtonEvent('multi pp', 'add new pp')
        sendGtmModalEventWithoutPage('open', 'edit pp')
    }

    const onModalClose = () => {
        setShowModal(false)
        ga.push({
            event: 'modalClosed',
            modalName: 'EditPaymentProfile',
        })
        sendGtmModalEventWithoutPage('close', 'add new pp')
    }

    const onNotificationClose = () => {
        setShowNotification(false)
        ga.push({
            event: 'modalClosed',
            modalName: 'ViewedMultiPPNotification',
        })
        sendGtmModalEventWithoutPage('close', 'multi pp notification')
    }

    const contactSupportTrack = () => {
        ga.push({
            event: 'modalClosed',
            modalName: 'ContactSupport',
        })
        sendGtmLinkEvent('multi pp', 'contact support')
    }

    const onCardEdit = ({ detail: body }: ValidatedPaymentCard) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        dispatch(updatePaymentProfileByAccountId({ clientId, body, notificationMessages }))
        setShowSpinner(true)
    }

    useEffect(() => {
        const { type } = notification
        if (type === ENotificationType.Clear) {
            return
        }
        onModalClose()
        setShowSpinner(false)
    }, [notification])

    return (
        <>
            {!featureFlags.removeContactUs() && showNotification && (
                <Modal
                    data-testid="popUpNotification"
                    canClose={false}
                    title="Why am I not seeing my payment details?"
                    style={{ marginTop: '26rem' }}
                >
                    <ModalBodyWrapper>
                        <Modal.Body style={{ paddingLeft: 'none' }} className="notificationBodyWrapper">
                            <NotificationBody>
                                <br />
                                <p>
                                    Your account has more than one registered payment method. Please add your preferred
                                    payment details for all of your billing from now.
                                </p>
                                <p>
                                    Please{' '}
                                    <a onClick={contactSupportTrack}>
                                        <ContactUs pageName={pageName} region={region} />{' '}
                                    </a>
                                    to manage multiple payment methods.
                                </p>
                            </NotificationBody>
                        </Modal.Body>
                    </ModalBodyWrapper>
                    <NotificationButtonWrapper>
                        <Modal.Footer className={'notification-footer'}>
                            <Button onClick={(): void => onNotificationClose()} className={'notification-ok-button'}>
                                OK
                            </Button>
                        </Modal.Footer>
                    </NotificationButtonWrapper>
                </Modal>
            )}
            {showModal && (
                <EditModal
                    isProcessing={showSpinner}
                    cancelModal={(): void => onModalClose()}
                    onSaveValidData={onCardEdit}
                    setProcessing={setShowSpinner}
                />
            )}
            <AddPaymentDetailWrapper
                className="addPaymentDetailWrapper"
                data-testid="addPaymentDetailWrapper"
                onClick={(): void => onModalOpen()}
            >
                <div className="addPaymentDetail">
                    <img data-testid="addPaymentDetailIcon" src={addPaymentDetailIcon} alt="Add a payment method" />
                    <div>Add a payment method</div>
                </div>
            </AddPaymentDetailWrapper>
        </>
    )
}
export default AddPaymentDetail
