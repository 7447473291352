import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HeaderWrapper, SupportRequestWrapper } from './styles';
import { BaseTemplate, Button, Tabs } from '@myob/myob-widgets';
import { navigateToUrl } from 'single-spa';
import { SupportRequestTable } from './components/SupportRequestTable';
import { AccountSelectContext, AccountSelectContextValue } from '@my-account/account';
import { fetchOpenSupportRequestsAsync, RequestListState } from './reducers/getRequestList';
import { AppThunkDispatch, RootState } from '../stores';
import { tabItems } from './components/constant';
import { Tab } from './type';
import Forbidden from 'components/Forbidden';
import ErrorComponent from 'components/ErrorComponent';
import { HttpStatus } from 'helpers/request';

export const SupportRequests: React.FC = () => {
  const dispatch: AppThunkDispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState(Tab.Open);

  const {
    fetch: {
      isLoading,
      isError,
      data: requestsInfo,
      error: { status },
    },
  } = useSelector<RootState, RequestListState>((state) => state.requestList);

  const {
    selected: selectedAccount,
    error: accountSelectError,
    isSoloAccount,
  } = useContext<AccountSelectContextValue>(AccountSelectContext);

  useEffect(() => {
    setSelectedTab(Tab.Open);
    if (!accountSelectError && selectedAccount?.clientId) {
      dispatch(fetchOpenSupportRequestsAsync({ id: selectedAccount.clientId, isClosed: false }));
    }
  }, [selectedAccount, accountSelectError]);

  const onTabChange = (tab) => {
    setSelectedTab(tab);
    const isClosed = tab === Tab.Closed;
    dispatch(fetchOpenSupportRequestsAsync({ id: selectedAccount.clientId, isClosed: isClosed }));
  };

  const goRaiseTicketPage = () => {
    navigateToUrl('/account/support/contact-support');
  };

  const content = useMemo(
    () => (
      <SupportRequestTable
        errorStatus={status}
        supportRequestsList={requestsInfo}
        isLoading={isLoading}
        selectedTab={selectedTab}
      />
    ),
    [requestsInfo, isError, accountSelectError, isLoading, selectedTab],
  );

  if (accountSelectError) {
    if (accountSelectError.status === HttpStatus.Forbidden) {
      return (
        <BaseTemplate>
          <Forbidden pageTitle="" title="You don’t have access to the page" description="" />
        </BaseTemplate>
      );
    }
    return (
      <BaseTemplate>
        <ErrorComponent pageTitle="" />
      </BaseTemplate>
    );
  }

  if (isSoloAccount) {
    return (
      <BaseTemplate>
        <Forbidden pageTitle="" title="You don’t have access to the page" description="" />
      </BaseTemplate>
    );
  }

  return (
    <BaseTemplate data-testid="viewSupportRequests">
      <HeaderWrapper>
        <div className="support-title">
          <h2>Support requests</h2>
          <div className="support-introduction">View and manage your requests.</div>
        </div>
        <div className="support-sub-title">
          <h3>Contact support</h3>
          <div className="support-introduction">Get help with your software, report bug or give us feedback.</div>
          <Button
            tone="brand"
            label="Contact support"
            className="contact-support-button"
            onClick={() => goRaiseTicketPage()}
          >
            Contact support
          </Button>
        </div>
      </HeaderWrapper>

      <SupportRequestWrapper data-testid="supportRequestWrapper">
        <h3>My support requests</h3>
        <Tabs
          items={tabItems}
          selected={selectedTab}
          onSelected={(tab) => {
            if (tab !== selectedTab && !isLoading) {
              onTabChange(tab);
            }
          }}
          className="support-request-tabs"
        />
        {content}
      </SupportRequestWrapper>
    </BaseTemplate>
  );
};
